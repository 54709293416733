@import '../../assets/styles/constants';

.ant-layout-header {
  padding: 0;
}

.ant-menu-submenu-title {
  a {
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: 100%
}

.ant-layout-sider {
  background-color: #fff;
}

.menu-wrapper {
  min-height: 100vh;
  box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.35);

  &>div {
    height: 100%;
  }
}

.aside-menu {
  overflow: auto;
  min-height: 100%;
  left: 0;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  z-index: 15;
  background-color: #444444;

  i.anticon {
    transform: translate(0px, 2px);
  }
}

.side-menu-logo {
  width: 100%;
  height: 64px;
  object-fit: contain;
  padding: 16px 20px;
  background-color: $MainBlack;
}

.ant-menu-submenu-active {
  color: $MainOrange !important;
}

.toggle-collapse-btn {
  margin: auto 24px;
}

.custom-layout-footer {
  display: flex;
  justify-content: space-between;

  max-width: 317px;
  margin-left: auto;
  margin-right:  auto;
  font-size: 14px;


  * {
    color: rgba(0, 0, 0, 0.45);
  }
}