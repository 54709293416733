$primary-color: #001529;
$header-height: 64px;

$pal-blue0: #ecf6fd;
$pal-blue1: #d2eafb;
$pal-blue2: #add8f7;
$pal-blue3: #7ec2f3;
$pal-blue4: #49a9ee;
$pal-blue5: #108ee9;
$pal-blue6: #0e77ca;
$pal-blue7: #0c60aa;
$pal-blue8: #09488a;
$pal-blue9: #073069;
$pal-blue-dark: #0c60aa;
$pal-blue-light: #7ec2f3;

$MainBlack: #555555;
$MainOrange: #ff6d04;
$MainRed: #c02425;


:root {
  --main-black: #555555;
  --main-orange: #ff6d04;
  --main-red: #c02425;
}