:root {
  --main-black: #555555;
  --main-orange: #ff6d04;
  --main-red: #c02425;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app-wrapper {
  min-height: 100vh;
  width: 100%;
}

.page-container {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  padding: 24px 24px;
  margin: 0;
}
.page-container > .ant-card {
  width: 100%;
}
.page-container .ant-tabs-bar {
  margin-bottom: 0;
}
.page-container div[role=tabpanel] {
  padding: 24px;
}

* {
  box-sizing: border-box;
}

.ant-row {
  width: 100%;
}

.ant-form-item {
  width: fit-content;
}

.ant-table-small {
  border: 0;
}

th.column-money,
td.column-money {
  text-align: right !important;
}

.ant-menu.ant-menu-light, .ant-menu.ant-menu-sub {
  background-color: #444;
  color: #fff;
}
.ant-menu.ant-menu-light .ant-menu-submenu-title:hover, .ant-menu.ant-menu-light .ant-menu-item:hover, .ant-menu.ant-menu-light .ant-menu-submenu-selected, .ant-menu.ant-menu-sub .ant-menu-submenu-title:hover, .ant-menu.ant-menu-sub .ant-menu-item:hover, .ant-menu.ant-menu-sub .ant-menu-submenu-selected {
  color: #ff6d04;
}
.ant-menu.ant-menu-light .ant-menu-item-selected, .ant-menu.ant-menu-sub .ant-menu-item-selected {
  background-color: #ff6d04;
  color: #fff;
}
.ant-menu.ant-menu-light .ant-menu-item-selected:hover, .ant-menu.ant-menu-sub .ant-menu-item-selected:hover {
  color: #fff;
}
.ant-menu.ant-menu-light .ant-menu-item-selected:after, .ant-menu.ant-menu-sub .ant-menu-item-selected:after {
  content: "";
  border-right: 0;
}

.ant-btn.ant-btn-primary {
  background-color: #ff6d04;
  border: unset;
}

.ant-btn.ant-btn-danger {
  background-color: #c02425;
  border: unset;
}

a {
  color: #ff6d04;
}

a:hover {
  color: #ff6d04;
}

.submit-search-bar {
  display: flex !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.ant-pagination-item-active {
  border: #ff6d04;
  background-color: #ff6d04;
}
.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-tabs-tab-active {
  color: #ff6d04 !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #ff6d04 !important;
}

div.ant-typography, .ant-typography p {
  margin-bottom: unset;
}

.search-section {
  margin-bottom: 16px;
}

.ant-table-thead > tr > th .ant-table-header-column {
  font-weight: bold;
}

.required-icon {
  color: var(--main-red);
  margin-right: 4px;
}

.ten-ad-btn {
  width: 96px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}
.ten-ad-btn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.primary-button {
  background-color: var(--main-orange);
  color: white;
  border: none;
}

.cancel-button {
  background-color: white;
  border: solid 1px #d9d9d9;
}

.draft-button {
  background-color: #d9d9d9;
  border: none;
}

.ant-page-header-back {
  margin: 4px 8px 4px 0;
}

.ant-page-header-heading-title {
  width: calc(100% - 40px);
}

.ant-modal-title {
  font-weight: bold;
}

@font-face {
  font-family: "photo-sign";
  src: url("../fonts/Photograph Signature.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #D0D0D0 18%, #F6F6F6 33%);
  background-size: 200vw 104px;
  position: relative;
}