@import './constants.scss';

html { height:100%; }

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-wrapper {
  min-height: 100vh;
  width: 100%;
}

.page-container {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  padding: 24px 24px;
  margin: 0;

  &>.ant-card {
    width: 100%;
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }
  div[role=tabpanel] {
    padding: 24px;
  }
}

* {
  box-sizing: border-box;
}

.ant-row {
  width: 100%;
}

.ant-form-item {
  width: fit-content;
}

.ant-table-small {
  border: 0;
}

th.column-money,
td.column-money {
  text-align: right !important;
}

.ant-menu.ant-menu-light, .ant-menu.ant-menu-sub {
  background-color: #444;
  color: #fff;

  .ant-menu-submenu-title:hover, .ant-menu-item:hover, .ant-menu-submenu-selected {
    color: $MainOrange;
  }

  .ant-menu-item-selected {
    background-color: $MainOrange;
    color: #fff;

    &:hover {
      color: #fff;
    }

    &:after {
      content: '';
      border-right: 0;
    }
  }
}

.ant-btn.ant-btn-primary {
  background-color: $MainOrange;
  border: unset;
}

.ant-btn.ant-btn-danger {
  background-color: $MainRed;
  border: unset;
}

a {
  color: $MainOrange
}

a:hover {
  color: $MainOrange;
}

.submit-search-bar {
  display: flex !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.ant-pagination-item-active {
  border: $MainOrange;
  background-color: $MainOrange;
  a {
    color: #fff !important;
  }
}

.ant-tabs-tab-active {
  color: $MainOrange !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: $MainOrange !important;
}

div.ant-typography, .ant-typography p {
  margin-bottom: unset;
}

.search-section {
  margin-bottom: 16px;
}

.ant-table-thead>tr>th .ant-table-header-column {
  font-weight: bold;
}

.required-icon {
  color: var(--main-red);
  margin-right: 4px;
}

.ten-ad-btn {
  width: 96px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.primary-button {
  background-color: var(--main-orange);
  color: white;
  border: none;
}

.cancel-button {
  background-color: white;
  border: solid 1px #d9d9d9;
}

.draft-button {
  background-color: #d9d9d9;
  border: none;
}

.ant-page-header-back {
  margin: 4px 8px 4px 0;
}

.ant-page-header-heading-title {
  width: calc(100% - 40px);
}

.ant-modal-title {
  font-weight: bold;
}