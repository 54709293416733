// Animation
@keyframes placeHolderShimmer{
  0%{
    background-position: -100vw 0
  }
  100%{
    background-position: 100vw 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #D0D0D0 18%, #F6F6F6 33%);
  background-size: 200vw 104px;
  position: relative;
}