.lightbox {
  .ant-modal-content {
    background-color: unset;
    box-shadow: unset;

    img {
      // padding: 24px
      // control width: 42px
      // control to image: 24px
      max-width: calc(100vw - 180px);
      // padding: 24px
      max-height: calc(100vh - 48px);
    }
  }
}